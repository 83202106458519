<template>
   <v-row>
    <v-col
      >
      <div class="pb-2 text-left">
        Select source classroom
		</div>
  <v-select
    label='Classroom'
    class="white"
    :items="data"
    item-text="name"
    item-value="id"
    :loading="api.isLoading"
    v-model="id"
    :rules="[v => !!v || 'Classroom is required']"
    required
  ></v-select>
  </v-col>
   </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.data = this.$_.filter(resp, classroom => classroom.id !== this.$route.params.parentId);
      if(resp[0] && resp[0].id){
        this.id = resp[0].id;
      }
    };
    //EOC
  },
   computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
  },
  watch: {
    id: function (val) {
      this.callbackSelect(val);
    },
  },
  methods: {
    fetch() {
      this.api.url =`${this.$api.servers.sso}/api/v1/en/data/institution/${this.$route.params.grandParentId}/classroom`
      this.$api.fetch(this.api);
    },
  },
  mounted(){
    this.fetch()
  }
};
</script>
